import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "notification__header" };
const _hoisted_2 = { class: "notification__title" };
const _hoisted_3 = ["innerHTML"];
import { storeToRefs } from 'pinia';
import { onMounted, reactive, watch, onUnmounted, provide } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { MODE, useAppStore } from "@/store/app";
import { useAuthStore } from "@/store/auth";
import { useWebSocket } from '@/hooks/useWebSocket';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup(__props) {
        const authStore = useAuthStore();
        const { isAuth } = storeToRefs(useAuthStore());
        const { mode } = storeToRefs(useAppStore());
        const router = useRouter();
        const route = useRoute();
        const data = reactive({
            notificationDuration: 3000
        });
        const { webSocket, connect, disconnect, data: dataWebSocket } = useWebSocket(process.env.VUE_APP_WS, {
            autoReconnect: true
        });
        provide('webSocketNotify', webSocket);
        provide('mainWebSocketData', dataWebSocket);
        watch(mode, (next) => {
            if (window.location.pathname === '/bcrm-login')
                return;
            if (next === MODE.script && route.name !== 'scripts')
                router.push({ name: 'scripts' });
            if (next === MODE.inactive && route.name !== 'index')
                router.push({ name: 'index' });
            if (next === MODE.pages && route.name !== 'index')
                router.push({ name: 'index' });
        });
        watch(isAuth, () => {
            if (isAuth.value) {
                connect();
            }
        });
        onMounted(() => {
            const token = localStorage.getItem('crm-token');
            if (token) {
                authStore.setIsAuth(true);
            }
            mode.value = MODE.script;
        });
        onUnmounted(() => {
            disconnect();
        });
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            const _component_base_icon = _resolveComponent("base-icon");
            const _component_Notifications = _resolveComponent("Notifications");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(_component_router_view),
                _createVNode(_component_Notifications, {
                    position: "bottom right",
                    duration: data.notificationDuration,
                    reverse: true,
                    width: "286px"
                }, {
                    body: _withCtx((props) => [
                        _createElementVNode("div", {
                            class: _normalizeClass([`notification`, props.item.type])
                        }, [
                            _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_base_icon, {
                                    class: "notification__icon",
                                    name: `notify-${props.item.type}`,
                                    width: "24",
                                    height: "25"
                                }, null, 8, ["name"]),
                                _createElementVNode("p", _hoisted_2, _toDisplayString(props.item.title), 1),
                                _createVNode(_component_base_icon, {
                                    class: "notification__close",
                                    name: "close",
                                    width: "18",
                                    height: "19",
                                    onClick: props.close
                                }, null, 8, ["onClick"])
                            ]),
                            _createElementVNode("div", {
                                class: "notification__text",
                                innerHTML: props.item.text
                            }, null, 8, _hoisted_3)
                        ], 2)
                    ]),
                    _: 1
                }, 8, ["duration"])
            ], 64));
        };
    }
});
