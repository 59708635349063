import { defineStore } from 'pinia';
export var MODE;
(function (MODE) {
    MODE["script"] = "script";
    MODE["pages"] = "pages";
    MODE["inactive"] = "inactive";
})(MODE || (MODE = {}));
export const useAppStore = defineStore('app', {
    state: () => {
        return {
            clientWindow: true,
            mode: MODE.inactive,
            appError: false
        };
    },
});
