import { appStore } from '@/main';
import { MODE } from "@/store/app";
const setComponent = (component) => ({ default: component });
const script = {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    children: [
        {
            name: 'index',
            path: '/',
            component: () => import('@/pages/Index.vue'),
            meta: {
                title: 'Интерактивный скрипт',
                name: 'Интерактивный скрипт'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages || appStore.mode === MODE.inactive)
                    next();
            }
        },
        {
            name: 'bcrm-login',
            path: '/bcrm-login',
            component: () => import('@/pages/BitrixLogin.vue'),
            meta: {
                title: 'Интерактивный скрипт: Логин',
                name: 'Логин'
            },
        },
        {
            name: 'scripts',
            path: '/scripts',
            component: () => import('@/pages/Scripts.vue'),
            meta: {
                title: 'Интерактивный скрипт: Мои скрипты',
                name: 'Мои скрипты'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.script)
                    to.matched[1].components = setComponent(() => import('@/pages/Scripts.vue'));
                if (appStore.mode !== MODE.script)
                    to.matched[1].components = setComponent(() => import('@/pages/Enumeration.vue'));
                next();
            }
        },
        {
            name: 'script-demo',
            path: '/scripts/:id/:version?',
            component: () => import('@/pages/ScriptDemo.vue'),
            meta: {
                title: (route) => `Интерактивный скрипт: Скрипт №${route.params.id}`,
                name: (route) => (`Скрипт №${route.params.id}`)
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'users',
            path: '/users',
            component: () => import('@/pages/Users.vue'),
            meta: {
                title: 'Интерактивный скрипт: Пользователи',
                name: 'Пользователи'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'analytics',
            path: '/analytics',
            component: () => import('@/pages/Analytics.vue'),
            meta: {
                title: 'Интерактивный скрипт: Аналитика',
                name: 'Аналитика'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'analytics-templates',
            path: '/analytics/:category',
            component: () => import('@/pages/AnalyticsTemplates.vue'),
            meta: {
                title: 'Интерактивный скрипт: Шаблоны отчетов',
                name: 'Шаблоны отчетов'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'analytics-report',
            path: '/analytics/:category/:id',
            component: () => import('@/pages/AnalyticsReport.vue'),
            meta: {
                title: 'Интерактивный скрипт: Аналитика',
                name: 'Аналитика'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'analytics-template-report',
            path: '/analytics/:category/:template/:id',
            component: () => import('@/pages/AnalyticsReport.vue'),
            meta: {
                title: 'Интерактивный скрипт: Аналитика',
                name: 'Аналитика'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'instructions',
            path: '/instructions',
            component: () => import('@/pages/Instructions.vue'),
            meta: {
                title: 'Интерактивный скрипт: Инструкции',
                name: 'Инструкции'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'constructor',
            path: '/constructor',
            component: () => import('@/pages/ConstructorTemplates.vue'),
            meta: {
                title: 'Интерактивный скрипт: Конструктор',
                name: 'Конструктор'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'constructor-development',
            path: '/constructor/development',
            component: () => import('@/pages/ConstructorDevelopment.vue'),
            meta: {
                title: 'Интерактивный скрипт: Конструктор',
                name: 'Конструктор'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'constructor-elements',
            path: '/constructor/elements',
            component: () => import('@/pages/ConstructorElements.vue'),
            meta: {
                title: 'Интерактивный скрипт: Конструктор',
                name: 'Конструктор'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'admin',
            path: '/admin',
            component: () => import('@/pages/Admin.vue'),
            meta: {
                title: 'Интерактивный скрипт: Администратор',
                name: 'Кабинет администратора'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'admin-type-binding',
            path: '/admin/type-binding',
            component: () => import('@/pages/AdminTypeBinding.vue'),
            meta: {
                title: 'Интерактивный скрипт: Администратор',
                name: 'Кабинет администратора'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
    ]
};
const constructor = {
    path: '/',
    children: [
        {
            name: 'constructor-edit',
            path: '/constructor/edit/:id/:version',
            component: () => import('@/pages/ConstructorEdit.vue'),
            meta: {
                title: 'Интерактивный скрипт: Конструктор',
                name: 'Конструктор'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'constructor-edit-dev',
            path: '/constructor/edit-dev/:id',
            component: () => import('@/pages/ConstructorEdit.vue'),
            meta: {
                title: 'Интерактивный скрипт: Конструктор',
                name: 'Конструктор'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
        {
            name: 'constructor-create',
            path: '/constructor/create',
            component: () => import('@/pages/ConstructorCreateScript.vue'),
            meta: {
                title: 'Интерактивный скрипт: Конструктор',
                name: 'Конструктор'
            },
            beforeEnter(to, from, next) {
                if (appStore.mode === MODE.pages)
                    next();
            }
        },
    ]
};
const routes = [
    script,
    constructor,
    {
        name: 'error',
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/Users.vue'),
        meta: {
            title: 'Интерактивный скрипт',
            name: 'Интерактивный скрипт'
        },
        beforeEnter(to, from, next) {
            // if (appStore.mode === MODE.pages || appStore.mode === MODE.inactive) next();
        }
    }
];
export default routes;
