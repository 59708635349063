import { ref } from 'vue'

export default function() {
  const offset = ref(0)

  function increaseOffset(count) {
    offset.value += count
  }

  function refreshOffset() {
    offset.value = 0
  }

  return {
    offset,
    increaseOffset,
    refreshOffset
  }
}