import { defineStore } from 'pinia';
import { getAuthPage, getToken, getRefreshToken } from '@/api/auth';
export function setTokenInLocalstorage(data) {
    const token = data.accessToken;
    const refreshToken = data.refreshToken;
    const userId = data.userData?.id || '';
    const memberId = data.memberId;
    const expires = data.expires - 250;
    const entityType = data.entity_type || '';
    const entityId = data.entity_id || '';
    const crmType = data.crm;
    const clientId = data.client_id;
    localStorage.setItem('crm-token', token);
    localStorage.setItem('crm-refresh-token', refreshToken);
    localStorage.setItem('crm-user', userId.toString());
    localStorage.setItem('crm-member-id', memberId);
    localStorage.setItem('crm-expires', expires.toString());
    localStorage.setItem('crm-entity-type', entityType);
    localStorage.setItem('crm-entity-id', entityId);
    localStorage.setItem('crm-type', crmType);
    localStorage.setItem('client-id', clientId);
    return token;
}
export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            isAuth: false,
        };
    },
    actions: {
        async redirectToAuthPage() {
            const response = await getAuthPage();
            const authorizationPage = response.authorization_page;
            const redirectURI = `${window.location.origin}/bcrm-login`;
            window.location.href = `${authorizationPage}&state=${redirectURI}`;
        },
        async getTokenUser(crmType, payload) {
            const response = await getToken(crmType, payload);
            return setTokenInLocalstorage(response);
        },
        async refreshToken(refreshToken) {
            const response = await getRefreshToken(refreshToken);
            return setTokenInLocalstorage(response);
        },
        setIsAuth(state) {
            this.isAuth = state;
        }
    }
});
