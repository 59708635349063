import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { BaseSearch, BaseInput, BaseMultiselect, BaseTextarea, BaseSelect, BaseCheckboxGroup, BaseButton, BaseLink, BaseDatepicker, BaseRadioGroup, BaseStringSelection, BaseAnchor } from "@/components/@base";
import { INPUT_MODE, SEARCH_PROPS_MODE, DATEPICKER_MODE, SELECT_MODE, RADIO_TYPES, BUTTON_TYPES } from "@/components/@base/@types";
import ScriptDinamicSelect from '@/components/scripts/select/ScriptDinamicSelect.vue';
import ScriptDinamicMultiSelect from '@/components/scripts/select/ScriptDinamicMultiSelect.vue';
import ScriptOktellRadioGroup from '@/components/scripts/oktell/ScriptOktellRadioGroup.vue';
import ScriptConsultingOktellRadioGroup from '@/components/scripts/oktell/ScriptConsultingOktellRadioGroup.vue';
import { DIRECTION, THEME } from "@/components/@types";
import { computed, shallowRef, reactive, onMounted, toRefs, watch } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ScriptElement',
    props: {
        element: { default: null },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        theme: { default: THEME.light },
        stop: { type: Boolean, default: false }
    },
    emits: ["update", "scrollTo", "confirmEntry", "search", "opened", "active", "refresh-search", "load-more"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const { element } = toRefs(props);
        const data = reactive({
            component: null
        });
        const scheme = [
            {
                types: [1, 2, 3, 5, 30],
                el: BaseInput
            },
            {
                types: [4, 31],
                el: BaseTextarea
            },
            {
                types: [6, 7],
                el: BaseSearch
            },
            {
                types: [8, 9, 32],
                el: element.value?.props?.bindElementId ? ScriptDinamicSelect : BaseSelect
            },
            {
                types: [10, 33],
                el: element.value?.props?.bindElementId ? ScriptDinamicMultiSelect : BaseMultiselect
            },
            {
                types: [11, 12, 13, 14],
                el: BaseRadioGroup
            },
            {
                types: [15, 16],
                el: BaseCheckboxGroup
            },
            {
                types: [17, 18, 19, 20],
                el: BaseDatepicker
            },
            {
                types: [21, 22, 23],
                el: BaseButton
            },
            {
                types: [24, 25],
                el: BaseStringSelection
            },
            {
                types: [26],
                el: BaseLink
            },
            {
                types: [27],
                el: BaseAnchor
            },
            {
                types: [28],
                el: ScriptOktellRadioGroup
            },
            {
                types: [29],
                el: ScriptConsultingOktellRadioGroup
            }
        ];
        const value = computed(() => {
            if (!element)
                return '';
            const val = props.stop ? element.value?.props?.tempValue : element.value?.value;
            return typeof val === 'string' ? val?.trim() || null : val || null;
        });
        const typeElement = computed(() => {
            return element.value?.type;
        });
        const mode = computed(() => {
            switch (typeElement.value) {
                case 2: return INPUT_MODE.nums;
                case 3: return INPUT_MODE.phone;
                case 5: return INPUT_MODE.float;
                case 6: return SEARCH_PROPS_MODE.default;
                case 7: return SEARCH_PROPS_MODE.catalog;
                case 9: return SELECT_MODE.scroll;
                case 17: return DATEPICKER_MODE.date;
                case 18: return DATEPICKER_MODE.datetime;
                case 19: return DATEPICKER_MODE.dates;
                case 20: return DATEPICKER_MODE.time;
                case 25: return INPUT_MODE.nums;
                case 30: return INPUT_MODE.email;
                default: return 'default';
            }
        });
        const type = computed(() => {
            switch (typeElement.value) {
                case 13: return RADIO_TYPES.button;
                case 14: return RADIO_TYPES.button;
                case 22: return BUTTON_TYPES.transparent;
                case 23: return BUTTON_TYPES.trgray;
                case 28: return RADIO_TYPES.button;
                case 29: return RADIO_TYPES.button;
                default: return 'default';
            }
        });
        const direction = computed(() => {
            switch (typeElement.value) {
                case 12:
                case 14:
                case 16: return DIRECTION.vertical;
                default: return DIRECTION.horizontal;
            }
        });
        const width = computed(() => {
            switch (typeElement.value) {
                case 17:
                case 18:
                case 19:
                case 20: return '';
                case 24:
                case 25: return '332px';
                default: return '300px';
            }
        });
        const hasSearch = computed(() => {
            switch (typeElement.value) {
                case 32:
                case 33: return true;
                default: return false;
            }
        });
        // const hasActive = computed(() => {
        // 	switch (typeElement.value) {
        // 		case 32: return {id: value.value, name: value.value};
        // 		case 33: return value?.value?.map(i => ({id: i, name: i}));
        // 		default: return null;
        // 	}
        // });
        function saveElementsFromCatalog(evt) {
            if (typeElement.value === 32 || typeElement.value === 33) {
                emits('active', evt);
            }
        }
        const componentsNameByGroupId = ['BaseRadioGroup', 'ScriptOktellRadioGroup', 'ScriptConsultingOktellRadioGroup'];
        const groupId = computed(() => {
            return componentsNameByGroupId.includes(data.component?.name) ? element.value.id : null;
        });
        const emits = __emit;
        const update = (v) => {
            emits('update', v);
        };
        const scrollTo = (id) => {
            emits('scrollTo', id);
        };
        const confirmEntry = () => {
            emits('confirmEntry');
        };
        const search = (str) => {
            refreshOffset();
            emits('search', str);
        };
        const loadMore = (str) => {
            emits('load-more', str);
        };
        const handleScroll = (str) => {
            if (typeElement.value === 32 || typeElement.value === 33 || typeElement.value === 7) {
                loadMore(str);
            }
        };
        const opened = () => {
            emits('opened');
            if (typeElement.value === 32 || typeElement.value === 33) {
                search('');
            }
        };
        const refreshOffset = () => {
            emits('refresh-search');
        };
        watch(element, next => {
            data.component = shallowRef(scheme?.find(i => typeElement.value && i.types.includes(typeElement.value))?.el) || null;
        }, { deep: true });
        onMounted(() => {
            data.component = shallowRef(scheme?.find(i => typeElement.value && i.types.includes(typeElement.value))?.el) || null;
        });
        return (_ctx, _cache) => {
            return (data.component)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(data.component), {
                    key: 0,
                    required: props.required,
                    width: width.value,
                    modelValue: value.value,
                    options: _unref(element)?.props?.options,
                    select: _unref(element)?.props?.select,
                    label: _unref(element)?.props?.label,
                    func: _unref(element)?.props?.func,
                    elementId: _unref(element)?.id,
                    groupId: groupId.value,
                    "bind-element-id": _unref(element)?.props?.bindElementId,
                    mode: mode.value,
                    type: type.value,
                    direction: direction.value,
                    href: _unref(element)?.props?.href,
                    download: _unref(element)?.props?.download,
                    anchor: _unref(element)?.props?.anchor,
                    "onUpdate:modelValue": update,
                    placeholder: _unref(element)?.props?.placeholder,
                    theme: props.theme,
                    disabled: props.disabled,
                    loading: _unref(element)?.props?.loading,
                    search: hasSearch.value,
                    active: hasSearch.value && _unref(element)?.props?.active || null,
                    onScrollTo: scrollTo,
                    onConfirmEntry: confirmEntry,
                    onSearch: search,
                    onOpened: opened,
                    onSave: saveElementsFromCatalog,
                    onReachEndY: handleScroll,
                    onRefreshOffset: refreshOffset
                }, null, 40, ["required", "width", "modelValue", "options", "select", "label", "func", "elementId", "groupId", "bind-element-id", "mode", "type", "direction", "href", "download", "anchor", "placeholder", "theme", "disabled", "loading", "search", "active"]))
                : _createCommentVNode("", true);
        };
    }
});
