import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "base-multiselect__list" };
const _hoisted_2 = {
    key: 0,
    class: "base-multiselect__search"
};
const _hoisted_3 = {
    key: 0,
    class: "base-multiselect__item"
};
const _hoisted_4 = {
    key: 1,
    class: "base-multiselect__item"
};
const _hoisted_5 = {
    key: 3,
    class: "base-multiselect__empty"
};
const _hoisted_6 = {
    key: 4,
    class: "base-multiselect__actions"
};
const _hoisted_7 = { class: "base-multiselect__actions-wrapper" };
import { THEME } from "@/components/@types";
import { computed, reactive, toRef, watch, ref } from "vue";
import { BaseCheckbox, BaseInput, BaseIcon, BaseLoader } from "@/components/@base";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { clickOutside as vClickOutside } from "@/directives";
import _ from "lodash";
const __default__ = { name: 'BaseMultiselect' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        placeholder: { default: '' },
        options: { default: () => [] },
        modelValue: { default: () => [] },
        required: { type: Boolean, default: false },
        theme: { default: THEME.light },
        width: { default: '100%' },
        disabled: { type: Boolean, default: false },
        height: { default: '140px' },
        search: { type: Boolean, default: false },
        active: { default: null },
        loading: { type: Boolean, default: false }
    },
    emits: ["update:modelValue", "save", "opened", "closed", "reach-end-y", "search", "refresh-offset"],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "281efe62": (props.width),
            "78509f3e": (props.height)
        }));
        const props = __props;
        const modelValue = toRef(props, 'modelValue');
        const opts = toRef(props, 'options');
        const data = reactive({
            isOpen: false,
            checked: _.cloneDeep(modelValue.value) || [],
            search: null,
            debounceTime: 1000,
            options: _.cloneDeep(props.options || [])?.map(i => {
                i.disabled = false;
                return i;
            })
        });
        const selectScroll = ref(undefined);
        const emits = __emit;
        const selectedName = computed(() => {
            if (!props.modelValue)
                return '';
            const arr = props.active && props.active?.filter(i => props.modelValue?.includes(i.id)).length > 0 ?
                props.active?.filter(i => props.modelValue?.includes(i.id)).map(i => i.name) :
                props.options.filter(i => props.modelValue?.includes(i.id)).map(i => i.name);
            return arr.join(', ');
        });
        const update = (v) => {
            if (v.checked && !data.checked?.includes(v.id))
                data.checked.push(v.id);
            if (!v.checked && data.checked?.includes(v.id))
                data.checked = data.checked.filter(i => i !== v.id);
        };
        const toggleOpen = () => {
            emits('refresh-offset');
            if (props.disabled)
                return;
            data.isOpen = !data.isOpen;
            if (data.isOpen)
                emits('opened');
            if (!data.isOpen) {
                data.checked = _.cloneDeep(props.modelValue) || [];
                emits('closed');
            }
            data.search = null;
            data.options?.forEach(i => i.disabled = false);
        };
        const close = () => {
            if (data.isOpen) {
                data.isOpen = false;
                data.checked = props.modelValue || [];
                emits('closed');
            }
        };
        const toggleAll = (v) => {
            if (v.checked) {
                data.options?.map(i => {
                    if (!data.checked.includes(i.id) && !i.disabled && !i.blocked)
                        data.checked.push(i.id);
                });
            }
            if (!v.checked && data.checked.length > 0) {
                data.options.map(i => {
                    if (data.checked.includes(i.id) && !i.disabled && !i.blocked) {
                        data.checked = data.checked?.filter(s => s !== i.id);
                    }
                });
            }
        };
        const save = () => {
            const ids = data.checked || [];
            const items = ids ? props.options?.filter(i => ids.includes(i.id)) : null;
            emits('update:modelValue', ids);
            emits('save', items);
            emits('refresh-offset');
            data.isOpen = false;
            emits('closed');
        };
        const search = _.debounce(() => {
            if (props.search && data.isOpen)
                emits('search', data.search);
        }, data.debounceTime);
        watch(modelValue, next => {
            data.checked = _.cloneDeep(next) || [];
        }, { deep: true });
        watch(opts, next => {
            data.options = _.cloneDeep(next || [])?.map(i => {
                i.disabled = false;
                return i;
            });
        }, { deep: true });
        const reachEndY = () => {
            if (data.options?.length > 0 && selectScroll.value.ps.reach.y === 'end' && !props.loading) {
                emits('reach-end-y', data.search);
            }
        };
        return (_ctx, _cache) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["base-multiselect", [data.isOpen && 'opened', props.theme, props.required && 'required', !props.modelValue && 'empty' || props.modelValue.length === 0 && 'empty', props.disabled && 'disabled']])
            }, [
                _createElementVNode("div", {
                    class: "base-multiselect__title",
                    onClick: toggleOpen
                }, _toDisplayString(selectedName.value || props.placeholder), 1),
                _createVNode(_unref(BaseIcon), {
                    class: "base-multiselect__chevron",
                    name: "chevron",
                    width: "16",
                    height: "17"
                }),
                _createElementVNode("div", _hoisted_1, [
                    (_ctx.$slots.search)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _renderSlot(_ctx.$slots, "search")
                        ]))
                        : _createCommentVNode("", true),
                    (props.search)
                        ? (_openBlock(), _createBlock(_unref(BaseInput), {
                            key: 1,
                            class: "base-multiselect__search",
                            modelValue: data.search,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((data.search) = $event)),
                            modelModifiers: { trim: true },
                            onInput: _unref(search),
                            placeholder: "Поиск",
                            theme: props.theme
                        }, {
                            actions: _withCtx(() => [
                                _createVNode(_unref(BaseIcon), {
                                    class: "base-multiselect__search-icon",
                                    name: "search"
                                })
                            ]),
                            _: 1
                        }, 8, ["modelValue", "onInput", "theme"]))
                        : _createCommentVNode("", true),
                    (props.options)
                        ? (_openBlock(), _createBlock(_unref(PerfectScrollbar), {
                            key: 2,
                            class: "base-multiselect__scroll",
                            onPsScrollDown: reachEndY,
                            ref_key: "selectScroll",
                            ref: selectScroll
                        }, {
                            default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (item) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                                        (!item.disabled)
                                            ? (_openBlock(), _createBlock(_unref(BaseCheckbox), {
                                                class: _normalizeClass(["base-multiselect__item", [item.blocked && 'blocked']]),
                                                key: item.id,
                                                checked: data.checked?.includes(item.id),
                                                id: item.id,
                                                label: item.name,
                                                theme: props.theme,
                                                disabled: item.blocked,
                                                onUpdate: update
                                            }, null, 8, ["class", "checked", "id", "label", "theme", "disabled"]))
                                            : _createCommentVNode("", true)
                                    ], 64));
                                }), 256)),
                                (_ctx.$slots.extra)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        _renderSlot(_ctx.$slots, "extra")
                                    ]))
                                    : _createCommentVNode("", true),
                                (props.loading)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                        _createVNode(_unref(BaseLoader))
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 3
                        }, 512))
                        : _createCommentVNode("", true),
                    (props.options?.length === 0 && _ctx.$slots.empty)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _renderSlot(_ctx.$slots, "empty")
                        ]))
                        : _createCommentVNode("", true),
                    (props.options?.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createVNode(_unref(BaseCheckbox), {
                                    class: "base-multiselect__toggle-all",
                                    checked: data.options?.filter(i => !i.disabled)?.length > 0 && data.options?.filter(i => !i.disabled).every(i => data.checked?.includes(i.id)),
                                    id: "all",
                                    label: "Выбрать все",
                                    theme: props.theme,
                                    onUpdate: toggleAll
                                }, null, 8, ["checked", "theme"])
                            ]),
                            _createVNode(_unref(BaseIcon), {
                                class: "base-multiselect__actions-btn base-multiselect__actions-btn--save",
                                name: "save",
                                hover: "",
                                theme: props.theme,
                                onClick: save
                            }, null, 8, ["theme"])
                        ]))
                        : _createCommentVNode("", true)
                ])
            ], 2)), [
                [_unref(vClickOutside), close]
            ]);
        };
    }
});
